import { DateTime, DateTimeMaybeValid, Duration, DurationMaybeValid, Interval, IntervalMaybeValid } from 'luxon';
import { Valid } from 'luxon/src/_util';

interface MaybeValid {
  isValid: boolean;
  invalidReason: null | string;
  invalidExplanation: null | string;
}

export function assertValid(dateTime: DateTimeMaybeValid): DateTime<Valid>;
export function assertValid(duration: DurationMaybeValid): Duration<Valid>;
export function assertValid(interval: IntervalMaybeValid): Interval<Valid>;
export function assertValid(val: MaybeValid): MaybeValid {
  if (!val.isValid) {
    throw new Error(`Invalid ${val.constructor.name}: ${val.invalidReason}: ${val.invalidExplanation}`);
  }
  return val;
}

export function isValid(dateTime: DateTimeMaybeValid): dateTime is DateTime<Valid>;
export function isValid(duration: DurationMaybeValid): duration is Duration<Valid>;
export function isValid(interval: IntervalMaybeValid): interval is Interval<Valid>;
export function isValid(val: MaybeValid): boolean {
  return val.isValid;
}
