import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  TUI_NOTIFICATION_OPTIONS,
  TuiAlertOptions,
  TuiAlertService,
  TuiDialogService,
  TuiNotificationDefaultOptions,
} from '@taiga-ui/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { has, isPlainObject } from 'lodash-es';
import { DateTime } from 'luxon';
import { Subscription } from 'rxjs';
import { AlertComponent } from './alert/alert.component';
import { ApiProblemResponse, ErrorInformation, SeverityType } from './notification-helper.model';

// This is our default if no severity code is provided
export const DEFAULT_SEVERITY = SeverityType.NOTIFICATION;

// This is our default if TUI_NOTIFICATION_OPTIONS does not provide a value or autoClose = true
export const DEFAULT_TIMEOUT_FALLBACK = 5000;

// Our regex to describe the structure of an error entry in translation file
export const ERROR_ENTRY_REGEX = /(^\[(\d+)](-\[(.+)])?-)?(.*)/;

const KEY_NOT_DEFINED = '**KEY_NOT_DEFINED**';

/**
 * Tuple that represents a business code replacement.
 * First entry is the origin (code to be replaced) and second the substitute (new code).
 */
type BusinessCodeReplacementTuple = [string, string];

/**
 * A config object to customize the auto handling error function.
 */
export interface AutoHandlingErrorOptions {
  /** Options to configure {@link TuiAlertService} */
  alertOptions?: TuiAlertOptions<unknown>;
  /**
   * A custom business code to be used in the problem response when no business error code is returned
   * In this case a {@link ApiProblemResponse} is constructed out of it.
   */
  fallbackBusinessCode?: string;
  /** Used to replace business error codes with a more specific one, for example when generic code "OTHER" is returned. */
  augmentBusinessCode?: BusinessCodeReplacementTuple[];
}

/**
 * A service that provides functionality for our automatic handling of error.
 * It also adds shortcuts for notifications.
 *
 * Under the hood it uses a special string format to be able to configure error handling at translation level.
 * See {@link ERROR_ENTRY_REGEX} for specification as regular expression. We further refer to it as "error entry format".
 */
@Injectable({ providedIn: 'root' })
export class NotificationHelper {
  private readonly subscriptions: Subscription[] = [];

  /**
   * The error entry format consists of three areas. This function extracts the severity.
   *
   * @param text - The error entry format string to process.
   */
  static extractSeverityCode(text: string): number | null {
    const result = text.match(ERROR_ENTRY_REGEX);
    return result?.[2] ? parseInt(result?.[2], 10) : null;
  }

  /**
   * The error entry format consists of three areas. This function extracts the title.
   *
   * @param text - The error entry format string to process.
   */
  static extractErrorTitle(text: string): string | null {
    const result = text.match(ERROR_ENTRY_REGEX);
    return result?.[4] ? result[4] : null;
  }

  /**
   * The error entry format consists of three areas. This function extracts the message.
   *
   * @param text - The error entry format string to process.
   */
  static extractErrorMessage(text: string): string {
    const result = text.match(ERROR_ENTRY_REGEX);
    return result?.[5] ? result[5] : text;
  }

  constructor(
    private readonly translate: TranslateService,
    @Inject(TuiAlertService) private readonly notify: TuiAlertService,
    @Inject(TUI_NOTIFICATION_OPTIONS) private readonly notificationOptions: TuiNotificationDefaultOptions,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
  ) {}

  /**
   * The "automatic" way to handle error responses from BFF.
   * Just call that function as error handler in Observable (higher order function).
   *
   * @param options - Optional options to configure {@link TuiAlertService}
   */
  autoHandleError(options?: AutoHandlingErrorOptions) {
    return (response: HttpErrorResponse) => {
      if (this.hasErrorObjectAndBusinessCode(response?.error)) {
        // error response has a business code
        // ****
        const problemResponse = response.error as ApiProblemResponse;

        // first, check if we need to replace the code with a more specific one
        if (options?.augmentBusinessCode) {
          const config = options.augmentBusinessCode;

          for (const [originalCode, substituteCode] of config) {
            if (problemResponse.businessCode === originalCode) {
              problemResponse.businessCode = substituteCode;
              const newResponse = {
                ...response,
                error: {
                  ...problemResponse,
                },
              };
              // the original business code matches our specification, so we use the replacement
              this.handleError(newResponse, options.alertOptions);
              return;
            }
          }
        }

        // when we reached this, we do default processing
        this.handleError(response, options?.alertOptions);
      } else {
        // error response has no business code
        // ****

        // then we might need a fallback
        if (options?.fallbackBusinessCode) {
          this.handleError(
            response,
            options?.alertOptions,
            this.constructApiProblemResponse(options.fallbackBusinessCode, response),
          );
          return;
        }

        // when we reached this, we do default processing
        // but as error is not a problem, we construct a default problem (probably FE_CONSTRUCTED)
        this.handleError(response, options?.alertOptions);
      }
    };
  }

  /**
   * The "manual" way to handle error responses from BFF.
   *
   * @param response - The error response object
   * @param notificationOptions - Optional options to configure {@link TuiAlertService}
   * @param defaultResponse - Optional options to configure {@link TuiAlertService}
   */
  handleError(
    response: HttpErrorResponse,
    notificationOptions?: TuiAlertOptions<unknown>,
    defaultResponse?: ApiProblemResponse,
  ): Subscription | null {
    if (response.status === 0) {
      // ** Client error ** -> A client-side (eg. exception thrown in an RxJS operator) or network error occurred.
      // These errors have status set to 0 and the error property contains a ProgressEvent object (further information).
      // Todo: What to do here exactly?
      // console.error('An error in response occurred:', response.error);
      return null;
    }

    const { severity, message, title } = this.extractErrorInformation(response, defaultResponse);

    if (severity === SeverityType.ALERT) {
      const sub = this.dialogService
        .open(new PolymorpheusComponent(AlertComponent), {
          dismissible: false,
          size: 's',
          data: {
            title: this.translate.instant('GENERAL.ERROR'),
            message,
            heading: title,
          },
        })
        .subscribe();
      this.subscriptions.push(sub);
      return sub;
    } else {
      const defaultTimeout: number = Number.isInteger(this.notificationOptions.autoClose)
        ? (this.notificationOptions.autoClose as number)
        : DEFAULT_TIMEOUT_FALLBACK;
      const baseOptions: Partial<TuiAlertOptions<unknown>> = {
        label: title,
        status: severity === SeverityType.WARNING ? 'warning' : 'error',
        icon: severity === SeverityType.WARNING ? 'warning' : 'error_outline',
        autoClose: severity === SeverityType.NOTIFICATION_CONFIRM ? false : defaultTimeout,
        hasCloseButton: true,
      };
      const options = {
        ...baseOptions,
        ...notificationOptions,
      };
      const sub = this.notify.open(message, options).subscribe();
      this.subscriptions.push(sub);
      return sub;
    }
  }

  /**
   * Extract error message from {@link ApiProblemResponse}
   *
   * @param error - The response from API containing the special error object
   */
  extractErrorMessage(error: ApiProblemResponse): string {
    const key = `ERRORS.${error.businessCode}`;
    const translated = this.translate.instant(key);
    if (translated === key) {
      return KEY_NOT_DEFINED;
    }
    if (error.businessCode === 'INVALID_INPUT_PARAMETERS') {
      return `${translated} [${error.invalidParams.map(e => `${e.name}:${e.reason}`).join(';')}]`;
    }
    if (error.businessCode === 'ENTITY_ALREADY_EXISTS_BY_FIELD') {
      return error.invalidParams[0].reason;
    }
    if (error.businessCode === 'FE_CONSTRUCTED') {
      return `${translated} (${error.technicalCode})`;
    }
    if (['OTHER', 'OTHER_ERROR', 'OTHER_SERVICE_EMPTY_RESPONSE'].includes(error.businessCode)) {
      return `${translated} (${error.technicalCode} / ${error.originatingServiceName})`;
    }
    return translated;
  }

  /**
   * Extract error message from {@link ApiProblemResponse}, but return a special message if key is not defined.
   *
   * @param error - The response from API containing the special error object
   */
  extractErrorText(error: ApiProblemResponse): string {
    const text = this.extractErrorMessage(error);
    if (text === KEY_NOT_DEFINED) {
      return `** No error message defined in translation file for key: ${error.businessCode}. Please add a corresponding key! **`;
    }
    return NotificationHelper.extractErrorMessage(text);
  }

  /**
   * Extract error severity from {@link ApiProblemResponse}
   *
   * @param error - The response from API containing the special error object
   */
  extractSeverity(error: ApiProblemResponse): SeverityType {
    const text = this.extractErrorMessage(error);
    const code = NotificationHelper.extractSeverityCode(text);
    if (code) {
      if (code > 4) {
        throw new Error(`Severity specified in translation text is not available. Severity: ${code}`);
      }
      return code;
    }
    return DEFAULT_SEVERITY;
  }

  /**
   * Extract error title from {@link ApiProblemResponse}
   *
   * @param error - The response from API containing the special error object
   * @param defaultTranslationKey - Optional key to when error message not defined, defaults to 'GENERAL.ERROR'
   */
  extractErrorTitle(error: ApiProblemResponse, defaultTranslationKey = 'GENERAL.ERROR'): string {
    const message = this.extractErrorMessage(error);
    if (message === KEY_NOT_DEFINED) {
      return this.translate.instant('GENERAL.ERROR');
    }
    const title = NotificationHelper.extractErrorTitle(message);
    if (['FE_CONSTRUCTED', 'OTHER', 'OTHER_ERROR', 'OTHER_SERVICE_EMPTY_RESPONSE'].includes(error.businessCode)) {
      return `${title} (${error.httpStatusCode})`;
    }
    return title ?? this.translate.instant(defaultTranslationKey);
  }

  extractErrorInformation(response: HttpErrorResponse, defaultResponse?: ApiProblemResponse): ErrorInformation {
    // ** Backend error ** -> The backend returned an unsuccessful response code such as 404 or 500.
    const error = defaultResponse ?? this.assertApiProblemResponse(response);
    const message = this.extractErrorText(error);
    const severity = this.extractSeverity(error);
    const title = this.extractErrorTitle(
      error,
      severity === SeverityType.WARNING ? 'GENERAL.WARNING' : 'GENERAL.ERROR',
    );
    return { title, message, severity };
  }

  /**
   * Shortcut for error notification.
   *
   * @param messageKey - The message, can be a translation key.
   * @param headlineKey - The optional headline, can be a translation key. Defaults to "GENERAL.ERROR"
   * @param interpolateParams - Params for interpolation of message translation
   */
  error(
    messageKey: string,
    headlineKey = 'GENERAL.ERROR',
    interpolateParams: Record<string, unknown> | undefined = undefined,
  ) {
    this.subscriptions.push(
      this.notify
        .open(this.translate.instant(messageKey, interpolateParams), {
          label: this.translate.instant(headlineKey, interpolateParams),
          status: 'error',
          icon: 'error_outline',
          hasCloseButton: true,
        })
        .subscribe(),
    );
  }

  /**
   * Shortcut for success notification.
   *
   * @param messageKey - The message, can be a translation key.
   * @param headlineKey - The optional headline, can be a translation key. Defaults to "GENERAL.SUCCESS"
   * @param interpolateParams - Params for interpolation of message translation
   */
  success(
    messageKey: string,
    headlineKey = 'GENERAL.SUCCESS',
    interpolateParams: Record<string, unknown> | undefined = undefined,
  ) {
    this.subscriptions.push(
      this.notify
        .open(this.translate.instant(messageKey, interpolateParams), {
          label: this.translate.instant(headlineKey, interpolateParams),
          status: 'success',
          icon: 'task_alt',
          hasCloseButton: true,
        })
        .subscribe(),
    );
  }

  /**
   * Shortcut for warning notification.
   *
   * @param messageKey - The message, can be a translation key.
   * @param headlineKey - The optional headline, can be a translation key. Defaults to "GENERAL.WARNING"
   * @param interpolateParams - Params for interpolation of message translation
   */
  warning(
    messageKey: string,
    headlineKey = 'GENERAL.WARNING',
    interpolateParams: Record<string, unknown> | undefined = undefined,
  ) {
    this.subscriptions.push(
      this.notify
        .open(this.translate.instant(messageKey, interpolateParams), {
          label: this.translate.instant(headlineKey, interpolateParams),
          status: 'warning',
          icon: 'warning',
          hasCloseButton: true,
        })
        .subscribe(),
    );
  }

  /**
   * Close all notifications directly.
   */
  closeAll() {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.subscriptions.length = 0;
  }

  /**
   * The response body should contain our BFF error object which provides more information.
   * If not, an equivalent problem is constructed here in client.
   *
   * @param response - The error response object
   */
  private assertApiProblemResponse(response: HttpErrorResponse): ApiProblemResponse {
    const error = response.error;
    if (this.hasErrorObjectAndBusinessCode(error)) {
      return response.error as ApiProblemResponse;
    }
    return this.constructApiProblemResponse('FE_CONSTRUCTED', response);
  }

  /**
   * Constructs a BFF error object.
   *
   * @param businessCode - A custom business code to be used in the problem response
   * @param response - The error response object
   */
  public constructApiProblemResponse(businessCode: string, response: HttpErrorResponse): ApiProblemResponse {
    return {
      businessCode,
      httpStatusCode: response.status,
      technicalCode: response.message,
      invalidParams: [],
      timestamp: DateTime.now().toJSON(),
      originatingServiceName: 'UNKNOWN',
    };
  }

  /**
   * Checks whether the response body contains BFF error object
   *
   * @param error - The BFF error object
   */
  public hasErrorObjectAndBusinessCode(error: unknown | null): boolean {
    return isPlainObject(error) && has(error, ['businessCode']);
  }
}
